import React, { ReactElement } from "react"
import { makeStyles } from '@material-ui/core/styles';
import { StaticImage } from 'gatsby-plugin-image'
import Container from '../components/Container'
import { colors, breakpoints } from '../styles/variables'
import { SharpImage } from '@intalex/gatsby-theme-base';

const sm = `@media (min-width: ${breakpoints.sm}px)`
const md = `@media (min-width: ${breakpoints.md}px)`
const lg = `@media (min-width: ${breakpoints.lg}px)`
const xl = `@media (min-width: ${breakpoints.xl}px)`

export const styles =
  makeStyles((theme) => ({
    heroContent: {
      padding: "4rem 2rem",
      display: "flex",
      flexDirection: "column",
      [md]: {
        flexDirection: "row",
        //marginLeft: "-25%",
        padding: "30px",
      },
      [lg]: {
        //padding: 0,
        //marginLeft: "-28%",
      }
    },
    heroHeader: {
      color: colors.white,
      fontWeight: "normal",
      marginBottom: "3rem",

      [md]: {
        fontSize: "3.2rem",
      }
    },
    overlayedGridImage: {
      gridArea: "1/1",
    },
    overlayedGridContent: {
      // By using the same grid area for both, they are stacked on top of each other
      gridArea: "1/1",
      position: "relative",
      // This centers the other elements inside the hero component
      //placeItems: "center",
      alignContent: "center",
      display: "grid",
      marginLeft: "16%",
    },
    img: {
      width: "100%",
      height: "100%",
      display: "flex",
    },
    imgContainer: {
      maxWidth: "500px",
      [lg]: {
        maxWidth: "600px",
      },
      [xl]: {
        maxWidth: "700px",
      }
    },
    imgLeftContainer: {
      maxWidth: "200px",
      marginRight: "50px",
      [lg]: {
        maxWidth: "240px",
      },
    },
    noImg: {
      [md]: {
        //marginLeft: "-25%",
      },
      [lg]: {
        //marginLeft: "-28%",
      }
    },
    imgLeft: {
      [md]: {
        marginLeft: "-5%",
      },
      [lg]: {
        marginLeft: "-15%",
      }
    }
  }));

interface Props {
  image?: string;
  images: Array<any>;
  children?: ReactElement;
  imageRight?: boolean;
}

/*This widget is solely for using the MUI Grid as a widget*/
const TitleBlock = ({ children, image, images, imageRight }: Props) => {
  const classes = styles();

  let asset = null;
  if (image && images)
    asset = images.filter(x => x.assetId === image)[0];

  return (
    <Container >
      <div style={{ display: "grid" }}>
        {/* You can use a GatsbyImage component if the image is dynamic */}
        <StaticImage
          style={{
            maxHeight: 720,
            marginTop: "-60px",
            zIndex: -5
          }}
          className={classes.overlayedGridImage}
          layout="fullWidth"
          // This is a presentational image, so the alt should be an empty string
          alt=""
          src={
            "../images/hero_bg.jpg"
          }
          formats={["auto", "webp", "avif"]}
        />
        <div className={`${classes.overlayedGridContent}`}>
          <div className={`${classes.heroContent} ${!asset ? classes.noImg : ""} ${asset && !imageRight ? classes.imgLeft : ""}`}>
            {!imageRight &&
              <div className={classes.imgLeftContainer}>
                {asset && asset.localFile ?
                  <div className={classes.img}><SharpImage file={asset.localFile} title={asset.fileName} altText={asset.fileName} /></div>
                  : <></>}
              </div>
            }
          <div style={{ maxWidth: "500px" }}>
            {children}
          </div>
            {imageRight &&
              <div className={classes.imgContainer}>
                {asset && asset.localFile ?
                  <div className={classes.img}><SharpImage file={asset.localFile} title={asset.fileName} altText={asset.fileName} /></div>
                  : <></>}
              </div>
            }
            </div>
        </div>
      </div>
    </Container>
  );
}

TitleBlock.NAME = "TitleBlock";
export default TitleBlock
