import React from "react"
import { styled } from '@material-ui/core/styles';
import Container from '../components/Container'
import { colors } from '../styles/variables'
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';


const SocialLink = styled('a')({
  color: colors.white,
  fontSize: '0.9rem',
  fontWeight: '400',
  lineHeight: '1.5rem',
  transition: 'all 0.1s',
  whiteSpace: 'nowrap',
  width: '48px',
  overflow: 'hidden',
  height: '48px',
  display: 'inline-block',
  margin: '0 0.2rem',
  border: 'none',
  borderRadius: "5px",

  '&:hover, &:focus': {
    textDecoration: 'none',
    color: colors.white,
    backgroundColor: colors.intalexGreen,
  }
})

const SocialsWidget = (props) => {

  return (
    <Container css={{textAlign: "center"}}>
      <SocialLink href="https://www.facebook.com/IntalexTechnologies/" rel="noreferrer" target="_blank" title="Facebook"><FacebookIcon style={{ fontSize: "2.9rem", margin: "1px 0px 0px 1px" }} /></SocialLink>
      <SocialLink href="https://www.linkedin.com/company/intalex-technologies" rel="noreferrer" target="_blank" title="LinkedIn"><LinkedInIcon style={{ fontSize: "2.9rem", margin: "1px 0px 0px 1px" }} /></SocialLink>
      <SocialLink href="https://twitter.com/IntalexTech" rel="noreferrer" target="_blank" title="Twitter"><TwitterIcon style={{ fontSize: "2.9rem", margin: "1px 0px 0px 1px" }} /></SocialLink>
      <SocialLink href="https://www.youtube.com/channel/UCvRJkl5UWIaotc859PNeNMA" rel="noreferrer" target="_blank" title="YouTube"><YouTubeIcon style={{ fontSize: "2.9rem", margin: "1px 0px 0px 1px" }} /></SocialLink>
      <SocialLink href="https://www.instagram.com/intalextechnologies/" rel="noreferrer" target="_blank" title="Instagram"><InstagramIcon style={{ fontSize: "2.9rem", margin: "1px 0px 0px 1px" }} /></SocialLink>
    </Container>
  );
}

SocialsWidget.NAME = "SocialsWidget";
export default SocialsWidget
