import * as React from 'react'
import { graphql } from 'gatsby'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import SEO from '../components/SEO'
import { WidgetParser } from "@intalex/gatsby-theme-base";
import TitleBlock from "../components/TitleBlock"
import SocialsWidget from "../components/SocialsWidget";

interface PageTemplateProps {
  data: {
    page: {
      author: {
        raw: string
      }
      name: string
      slug: {
        raw: string
      }
      content: {
        raw: string
        rendered: string
        excerpt: string
      }
      contentType: {
        name: string
      }
      title: {
        raw: string
      }
      description: {
        raw: string
      }
      keywords: {
        raw: string
      }
    },
    pageBlocks: {
      nodes: []
    },
    pages: {
      nodes: []
    },
    images: {
      nodes: []
    },
    people: {
      nodes: []
    },
    forms: {
      nodes: []
    },
    site: {
      siteMetadata: {
        formlex: {
          returnPath: string
          submissionsUrl: string
        }
        siteUrl: string
      }
    }
  }
}


const PageTemplate: React.FC<PageTemplateProps> = ({ data }) => {

  const bindings = {
    page: {
      ...data.page,
      blocks: data.pageBlocks.nodes,
    },
    pages: data.pages.nodes,
    images: data.images.nodes,
    people: data.people.nodes,
    forms: data.forms.nodes,
    submissionsUrl: data.site.siteMetadata.formlex.submissionsUrl,
    returnUrl: data.site.siteMetadata.formlex.returnPath,
  };

  const additionalWidgets = [
    {
      shortcode: "title",
      component: TitleBlock,
      attributes: {
        images: "images",
        pages: "pages",
      }
    },
    {
      shortcode: "socials",
      component: SocialsWidget,
    },
  ]

  return (
    <IndexLayout>
      <SEO
        title={data.page.title.raw}
        description={data.page.description.raw}
        keywords={data.page.keywords.raw}
      />
      <Page>
        <Container>
          <WidgetParser
            bindings={bindings}
            jsx={data.page.content.rendered}
            additionalWidgets={additionalWidgets}
          />
        </Container>
      </Page>
    </IndexLayout>
  )
}

export default PageTemplate

export const query = graphql`
  query PageById($id: String!, $images: [String!]!, $pages: [String!]!, $pageBlocks: [String!]!, $people: [String!]!) {
    page: contentPage(id: { eq: $id }) {
      author {
        raw
      }
      name
      slug {
        raw
      }
      contentType {
        name
      }
      title {
        raw
      }
      content {
        rendered
      }
      description {
        raw
      }
      keywords {
        raw
      }
    }
    pageBlocks: allContentPageBlock(filter: {contentType: {name: {eq: "PageBlock"}}, itemId: {in: $pageBlocks}}) {
      nodes {
        id:itemId
        name
        title {
          raw
        }
        content {
          rendered
        }
        image {
          childImageSharp {
            gatsbyImageData(width: 1000)
          }
        }
        contentType {
          name
        }
        sortIndex {
          raw
        }
      }
    }
    images: allContentAsset(filter: {mediaType: {in: ["image/jpeg", "image/png"]}, assetId: {in: $images}}) {
      nodes {
        assetId
        fileName
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(transformOptions: {fit: CONTAIN})
          }
        }
      }
    }
    pages: allContentPage(filter: {itemId: {in: $pages}}) {
      nodes {
        id
        itemId
        slug {
            raw
        }
      }
    }
    people: allContentPerson(filter: {itemId: {in: $people}}) {
      nodes {
        id
        itemId
        bio {
          rendered
        }
        name
        image {
          childImageSharp {
            gatsbyImageData(transformOptions: {fit: CONTAIN})
          }
        }
      }
    }
    forms:allFormlexForm {
        nodes {
            dateCreated
            formId
            formFields {
              fieldId
              fieldType
              mandatory
              multiline
              name
              sortIndex
              validationRegex
              label
            }
            name
            status {
              name
            }
        }
      }
      site {
        siteMetadata {
          formlex {
            returnPath
            submissionsUrl
          }
          siteUrl
        }
      }
  }
`
